import Vue from "vue";

const data = Vue.observable({
  orientation: "portrait",
});

function handleResize() {
  switch (screen.orientation.type) {
    case "landscape-primary":
    case "landscape-secondary":
      data.orientation = "landscape";
      break;

    default:
      data.orientation = "portrait";
      break;
  }
}

export const OrientationMixin = {
  computed: {
    $_orientation() {
      return data.orientation;
    },
  },

  mounted() {
    window.addEventListener("resize", handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", handleResize);
  },
};