var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title
    ? _c("span", { attrs: { title: _vm.title } }, [_c("IconRouted")], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }