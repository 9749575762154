var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "forwardMessage",
        centered: "",
        "hide-footer": "",
        size: "lg",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("b-container", { attrs: { fluid: "" } }),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "link" },
                  on: { click: close },
                },
                [
                  _c("b-icon", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { icon: "x-circle-fill", variant: "secondary" },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "b-row",
        { staticClass: "tw-pb-8" },
        [
          _c("b-col", { attrs: { cols: "12", sm: "4" } }, [
            _c(
              "h1",
              {
                staticClass: "heading mb-4",
                staticStyle: { "line-height": "30px" },
              },
              [_vm._v(" Forward"), _c("br"), _vm._v("Request ")]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "8" } },
            [
              _c("div", { staticClass: "d-flex align-items-center mb-3" }, [
                _c(
                  "p",
                  {
                    staticClass: "mb-0 mr-3",
                    staticStyle: { "font-size": "16px" },
                  },
                  [_vm._v("from")]
                ),
                _vm.conversationmemberlist.length > 0 &&
                _vm.conversationmemberlist.find(
                  (member) => member.type === "PATIENT"
                )
                  ? _c(
                      "p",
                      {
                        staticClass: "mb-0",
                        staticStyle: {
                          "font-size": "22px",
                          "font-weight": "500",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.conversationmemberlist.find(
                                (member) => member.type === "PATIENT"
                              ).name
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-flex-grow tw-flex-col-reverse tw-bg-[#F9FBFB] tw-px-4 tw-pt-4 tw-overflow-y-scroll tw-max-h-[600px]",
                },
                [
                  _vm._l(_vm.messages, function (chat, i) {
                    return [
                      _c(
                        "div",
                        {
                          key: i,
                          staticClass: "bubble-chat tw-flex tw-items-end",
                          class: [
                            chat.userid != _vm.userid ? "patient" : "doctor",
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-flex-shrink-0 img-holder tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden",
                              staticStyle: { "font-size": "0" },
                            },
                            [
                              _c("img", {
                                staticClass: "tw-object-cover",
                                attrs: {
                                  src: _vm.getMemberProfileUrl(chat.userid),
                                  width: "30",
                                  height: "30",
                                  alt: "name of the patient or doctor",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "chat-container tw-flex-0" },
                            [
                              chat.attachment && chat.attachment.length > 0
                                ? [
                                    _vm._l(
                                      chat.attachment,
                                      function (chatAttachment, i) {
                                        return [
                                          chatAttachment.type !=
                                          "application/pdf"
                                            ? _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "shared-image tw-flex tw-flex-wrap",
                                                  class: chat.message
                                                    ? "tw-mb-3"
                                                    : "",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: chatAttachment.url,
                                                        variant: "link",
                                                        download: "",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "tw-object-cover tw-w-full tw-h-full",
                                                        attrs: {
                                                          src: chatAttachment.url,
                                                          width: "250",
                                                          height: "250",
                                                          alt: chatAttachment.filename,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : [
                                                _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "messages p-3",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: chatAttachment.url,
                                                          variant: "link",
                                                          download: "",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "arrow-down-circle-fill",
                                                            variant: "primary",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              chatAttachment.filename
                                                            )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                        ]
                                      }
                                    ),
                                  ]
                                : _vm._e(),
                              chat.message
                                ? _c("span", { staticClass: "messages p-3" }, [
                                    _vm._v(" " + _vm._s(chat.message) + " "),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "timestamp tw-flex-shrink-0 tw-items-end",
                            },
                            [
                              _c(
                                "small",
                                { staticClass: "tw-text-[10px] tw-text-muted" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.moment
                                          .unix(chat.sentat)
                                          .format("MMM DD, YYYY ")
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.moment
                                          .unix(chat.sentat)
                                          .format("hh:mm A")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "small",
                { staticClass: "text-muted tw-inline-block tw-mb-6" },
                [_vm._v(" Forward these messages to customer care ")]
              ),
              _c("ValidationObserver", {
                ref: "notesForm",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "customercare-note",
                            rules: "required",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "customercare-note",
                                        placeholder: "Note to customer care",
                                        rows: "3",
                                        "max-rows": "6",
                                      },
                                      model: {
                                        value: _vm.notes,
                                        callback: function ($$v) {
                                          _vm.notes = $$v
                                        },
                                        expression: "notes",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-justify-end mt-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-space-x-2 tw-w-full lg:tw-w-4/5 tw-flex",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      pill: "",
                                      block: "",
                                      variant: "outline-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide(
                                          "forwardMessage"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      disabled: _vm.isProcessing,
                                      pill: "",
                                      block: "",
                                      variant: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return handleSubmit(_vm.onSend)
                                      },
                                    },
                                  },
                                  [
                                    _vm.isProcessing
                                      ? _c("b-spinner", {
                                          attrs: { small: "" },
                                        })
                                      : _c("span", [_vm._v("Send")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }