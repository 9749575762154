<template>
  <b-modal id="forwardMessage" centered hide-footer size="lg">
    <template v-slot:modal-header="{ close }">
      <b-container fluid></b-container>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="link" @click="close">
        <b-icon
          icon="x-circle-fill"
          variant="secondary"
          style="width: 20px; height: 20px"
        ></b-icon>
      </b-button>
    </template>
    <b-row class="tw-pb-8">
      <b-col cols="12" sm="4">
        <h1 class="heading mb-4" style="line-height: 30px">
          Forward<br />Request
        </h1>
      </b-col>
      <b-col cols="12" sm="8">
        <div class="d-flex align-items-center mb-3">
          <p class="mb-0 mr-3" style="font-size: 16px">from</p>
          <p
            class="mb-0"
            style="font-size: 22px; font-weight: 500"
            v-if="
              conversationmemberlist.length > 0 &&
              conversationmemberlist.find((member) => member.type === 'PATIENT')
            "
          >
            {{
              conversationmemberlist.find((member) => member.type === "PATIENT")
                .name
            }}
          </p>
        </div>
        <div
          class="
            tw-flex
            tw-flex-grow
            tw-flex-col-reverse
            tw-bg-[#F9FBFB]
            tw-px-4
            tw-pt-4
            tw-overflow-y-scroll
            tw-max-h-[600px]
          "
        >
          <template v-for="(chat, i) in messages">
            <div
              :key="i"
              class="bubble-chat tw-flex tw-items-end"
              :class="[chat.userid != userid ? 'patient' : 'doctor']"
            >
              <div
                class="
                  tw-flex-shrink-0
                  img-holder
                  tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden
                "
                style="font-size: 0"
              >
                <img
                  class="tw-object-cover"
                  :src="getMemberProfileUrl(chat.userid)"
                  width="30"
                  height="30"
                  alt="name of the patient or doctor"
                />
              </div>

              <div class="chat-container tw-flex-0">
                <template v-if="chat.attachment && chat.attachment.length > 0">
                  <template v-for="(chatAttachment, i) in chat.attachment">
                    <div
                      :key="i"
                      class="shared-image tw-flex tw-flex-wrap"
                      :class="chat.message ? 'tw-mb-3' : ''"
                      v-if="chatAttachment.type != 'application/pdf'"
                    >
                      <a
                        :href="chatAttachment.url"
                        variant="link"
                        download
                        target="_blank"
                      >
                        <img
                          class="tw-object-cover tw-w-full tw-h-full"
                          :src="chatAttachment.url"
                          width="250"
                          height="250"
                          :alt="chatAttachment.filename"
                        />
                      </a>
                    </div>
                    <template v-else>
                      <div :key="i" class="messages p-3">
                        <a :href="chatAttachment.url" variant="link" download
                          ><b-icon
                            icon="arrow-down-circle-fill"
                            variant="primary"
                          ></b-icon>
                          {{ chatAttachment.filename }}</a
                        >
                      </div>
                    </template>
                  </template>
                </template>
                <span class="messages p-3" v-if="chat.message">
                  {{ chat.message }}
                </span>
              </div>
              <div class="timestamp tw-flex-shrink-0 tw-items-end">
                <small class="tw-text-[10px] tw-text-muted">
                  {{ moment.unix(chat.sentat).format("MMM DD, YYYY ") }}
                  {{ moment.unix(chat.sentat).format("hh:mm A") }}
                </small>
              </div>
            </div>
          </template>
        </div>
        <small class="text-muted tw-inline-block tw-mb-6">
          Forward these messages to customer care
        </small>
        <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
          <ValidationProvider
            name="customercare-note"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-textarea
              id="customercare-note"
              v-model="notes"
              placeholder="Note to customer care"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <div class="tw-flex tw-justify-end mt-3">
            <div class="tw-space-x-2 tw-w-full lg:tw-w-4/5 tw-flex">
              <b-button
                pill
                block
                variant="outline-primary"
                @click="$bvModal.hide('forwardMessage')"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="isProcessing"
                pill
                block
                variant="primary"
                @click="handleSubmit(onSend)"
              >
                <b-spinner v-if="isProcessing" small></b-spinner>
                <span v-else>Send</span>
              </b-button>
            </div>
          </div>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import _ from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  props: {
    selectedMessages: {
      type: Array,
      default: () => [],
    },
    conversationmemberlist: {
      type: Array,
      default: () => [],
    },
    conversationid: {
      type: String,
      default: "",
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      userid: "",
      notes: "",
      isProcessing: false,
    };
  },
  mounted() {
    this.userid = localStorage.getItem("userid");
  },
  computed: {
    messages() {
      return _.orderBy(this.selectedMessages, ["sentat"], ["desc"]);
    },
  },
  methods: {
    getMemberProfileUrl(userid) {
      let member = this.conversationmemberlist.find((x) => x.userId === userid);
      if (member) {
        return member.profileUrl;
      } else {
        return "";
      }
    },
    async onSend() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("message/forwardRequest", {
          conversationid: this.conversationid,
          payload: {
            messageid: this.messages.map((msg) => msg.id),
            doctornotes: this.notes,
          },
        });
        this.isProcessing = false;
        this.notes = "";
        this.$bvModal.hide("forwardMessage");
        this.$emit("success");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
/deep/#forwardMessage .modal-header {
  border-bottom: none;
}

/deep/#forwardMessage .modal-body {
  padding-top: 0;
}

/deep/#customercare-note {
  padding-left: 0;
  padding-right: 0;
}

.btn-block + .btn-block {
  margin-top: 0;
}
</style>

<style lang="scss" scoped>
.bubble-chat {
  width: 100%;
  margin-bottom: 1rem;

  .messages {
    display: inline-block;
    word-break: break-word;
  }

  &.patient {
    .img-holder {
      margin-right: 8px;
    }

    .timestamp {
      margin-left: 8px;
    }

    .shared-image {
      justify-content: start;
    }
  }

  &.doctor {
    flex-direction: row-reverse;
    justify-content: end;

    .img-holder {
      margin-left: 8px;
    }

    .timestamp {
      margin-right: 8px;
      text-align: right;
    }

    .shared-image {
      justify-content: end;
    }
  }
}

.bubble-chat.patient .messages {
  background-color: #ffecca;
}

.darkmode .bubble-chat.patient .messages {
  background-color: rgba(255, 236, 202, 0.1);
}

.bubble-chat.doctor .messages {
  background-color: #ffffff;
}

.chat-container {
  max-width: 70%;
  overflow: hidden;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    max-width: 50%;
  }
}
</style>
