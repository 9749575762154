<template>
  <div class="canned-reply-modal pt-2">
    <p class="text-muted">Reply</p>
    <b-row>
      <b-col>
        <b-input-group>
          <b-input-group-append>
            <span class="input-group-text">
              <i class="fe fe-search"></i>
            </span>
          </b-input-group-append>
          <b-form-input
            v-model="search"
            class="form-control list-search"
            placeholder="Search"
            @keyup.enter="searchReplies"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-select
          v-model="selectedCategory"
          :options="categoryOptions"
          @input="getCategory"
        >
          <template #first>
            <b-form-select-option value=""> All Replies </b-form-select-option>
            <b-form-select-option value="default">
              Defaults
            </b-form-select-option>
            <b-form-select-option value="" disabled>----</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-list-group
          ref="canned-reply-modal-list"
          class="canned-reply-modal-list"
        >
          <div v-if="selectedCategory == '' || selectedCategory == 'default'">
            <b-list-group-item
              v-for="item in defaultReplies"
              :key="item.id"
              @click="setActiveItem(item.id)"
              :active="item.id == activeItemID"
            >
              {{ item.name }}
            </b-list-group-item>
          </div>
          <b-list-group-item value="" disabled>----</b-list-group-item>
          <b-list-group-item
            v-for="item in savedReplies"
            :key="item.id"
            @click="setActiveItem(item.id)"
            :active="item.id == activeItemID"
          >
            {{ item.name }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <p class="text-muted">Content</p>
        <b-form-textarea
          placeholder="Message content here..."
          v-model="activeItemContent"
          rows="6"
          no-resize
        >
        </b-form-textarea>
      </b-col>
    </b-row>
    <b-row v-if="attachments && attachments.length > 0">
      <b-col>
        <ul class="attachments-list">
          <li
            v-for="(file, index) in attachments"
            :key="`${index} - ${file.name}`"
          >
            <a :href="file.previewURL" target="_blank">{{ file.filename }}</a>
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row
      class="pt-4 pb-4 canned-footer"
      style="position: sticky; left: 0; right: 0; bottom: 0"
    >
      <b-col cols="6" sm="6">
        <b-button
          variant="outline-primary"
          size="lg"
          pill
          block
          @click="$emit('close-canned')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col cols="6" sm="6">
        <b-button
          variant="primary"
          size="lg"
          pill
          block
          @click="sendReply"
          :disabled="!patientName"
        >
          Select
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ReplyService } from "@/services/reply.service";

export default {
  data() {
    return {
      search: "",
      categories: [],
      defaultReplies: [],
      savedReplies: [],
      activeItemID: "",
      activeItemName: "",
      activeItemContent: "",
      attachments: [],

      // Categories
      selectedCategory: "",
    };
  },
  props: {
    patientName: {
      type: String,
      default: "",
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    categoryOptions() {
      let categories = [];
      for (let item of this.categories) {
        categories.push({ value: item.id, text: item.name });
      }
      categories.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      );
      return categories;
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.getReplies();
        this.getReplyCategories();
      }
    },
  },
  methods: {
    async searchReplies() {
      if (!this.search) {
        this.getReplies();
      } else {
        const res = await ReplyService.searchReplies(this.search);
        if (res.data.length == 1) {
          this.setActiveItem(res.data[0]);
        }
        this.savedReplies = res.data;
        this.savedReplies.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        await this.$nextTick();
        this.$refs["canned-reply-modal-list"].scrollTop = 0;
      }
    },
    async getReplies() {
      const res = await ReplyService.listReplies();
      this.savedReplies = res.data;
      this.savedReplies.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
    async setActiveItem(id) {
      if (id.includes("default")) {
        const item = this.defaultReplies.find((o) => o.id == id);
        this.activeItemID = item.id;
        this.activeItemName = item.name;
        this.activeItemContent = item.content;
        return;
      }

      this.activeItemID = id;
      // this.activeItemName = item.name;
      // this.activeItemContent = item.content;
      const res = await ReplyService.getReply(id);
      this.activeItemName = res.data.name;
      this.activeItemContent = res.data.content;
      if (res.data.attachment) {
        this.apiFileItemNumber = res.data.attachment.length;
        this.attachments = res.data.attachment;
      } else {
        this.attachments = [];
      }
    },
    async sendReply() {
      if (this.attachments.length > 0) {
        try {
          // const mForm = new FormData();
          for (let file of this.attachments) {
            let res = await ReplyService.getAttachment(file.previewURL);
            // let blob = new Blob([res.data], { type: file.type });
            console.log(res);
            let fileToSubmit = new File([res.data], file.filename, {
              type: file.type,
            });
            fileToSubmit.url = URL.createObjectURL(fileToSubmit);
            console.log(fileToSubmit);
            this.$emit("add-attachment", fileToSubmit);
          }
        } catch (err) {
          if (err.hasOwnProperty(status) && err.status == 403) {
            this.setActiveItem(this.activeItemID);
            this.sendReply();
          } else {
            console.log(err);
          }
        }
      }
      this.$emit("add-message", this.activeItemContent);
    },
    async getCategory() {
      if (this.selectedCategory == "") {
        this.getReplies();
      } else {
        const { data } = await ReplyService.searchReplyByCategory(
          this.selectedCategory
        );
        this.savedReplies = data;
        this.savedReplies.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      }
    },
    async getReplyCategories() {
      const { data } = await ReplyService.listReplyCategory();
      this.categories = data;
    },
  },
  mounted() {
    this.defaultReplies = [...this.$store.state.reply.defaultReplies];
  },
};
</script>

<style>


.canned-footer {
  background-color: #fff;
}

.canned-reply-modal {
  padding: 0px 1rem;
  position: relative;
}

.canned-reply-modal-list {
  height: 250px;
  overflow: auto;
}

.attachments-list {
  height: 40px;
  overflow: auto;
}
</style>
