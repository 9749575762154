<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.07644 12.9236L8.11111 9.88889H11.6667C12.1382 9.88889 12.5903 9.70159 12.9237 9.36819C13.2571 9.03479 13.4444 8.58261 13.4444 8.11111V4.55556V2.77778C13.4444 2.30628 13.2571 1.8541 12.9237 1.5207C12.5903 1.1873 12.1382 1 11.6667 1H2.77778C2.30628 1 1.8541 1.1873 1.5207 1.5207C1.1873 1.8541 1 2.30628 1 2.77778V8.11111C1 8.58261 1.1873 9.03479 1.5207 9.36819C1.8541 9.70159 2.30628 9.88889 2.77778 9.88889H4.55556V13.4444L5.07644 12.9236Z"
      fill="white"
    />
    <path
      d="M5.07644 12.9236C5.24131 13.0889 5.43722 13.22 5.65292 13.3094C5.86861 13.3988 6.09985 13.4447 6.33333 13.4444H9.88889L13.4444 16.1111V13.4444H15.2222C15.6937 13.4444 16.1459 13.2571 16.4793 12.9237C16.8127 12.5903 17 12.1382 17 11.6667V6.33333C17 5.86184 16.8127 5.40965 16.4793 5.07625C16.1459 4.74286 15.6937 4.55556 15.2222 4.55556H13.4444M5.07644 12.9236L8.11111 9.88889H11.6667C12.1382 9.88889 12.5903 9.70159 12.9237 9.36819C13.2571 9.03479 13.4444 8.58261 13.4444 8.11111V4.55556M5.07644 12.9236L4.55556 13.4444V9.88889H2.77778C2.30628 9.88889 1.8541 9.70159 1.5207 9.36819C1.1873 9.03479 1 8.58261 1 8.11111V2.77778C1 2.30628 1.1873 1.8541 1.5207 1.5207C1.8541 1.1873 2.30628 1 2.77778 1H11.6667C12.1382 1 12.5903 1.1873 12.9237 1.5207C13.2571 1.8541 13.4444 2.30628 13.4444 2.77778V4.55556"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line x1="7.5" y1="3" x2="7.5" y2="8" stroke="currentColor" />
    <line x1="10" y1="5.5" x2="5" y2="5.5" stroke="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
