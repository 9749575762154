<template>
  <div>
    <b-modal
      id="viewForwardMessage"
      v-model="isModalVisible"
      centered
      hide-header
      hide-footer
      size="lg"
      @show="onShow"
    >
      <div class="tw-p-4">
        <div class="tw-flex tw-justify-between tw-mb-10">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>

          <div
            class="tw-cursor-pointer"
            @click="$bvModal.hide('viewForwardMessage')"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L18 18" stroke="#0C3523" stroke-width="1.6" />
              <path d="M18 1L1 18" stroke="#0C3523" stroke-width="1.6" />
            </svg>
          </div>
        </div>

        <div>
          <h3 class="tw-text-xl tw-mb-6">Forward Requests</h3>
          <b-table responsive striped :items="items" :fields="fields">
            <template v-slot:cell(action)="data">
              <div class="text-right">
                <b-button
                  pill
                  variant="link"
                  size="sm"
                  class="
                    text-secondary
                    tw-capitalize tw-tracking-normal tw-text-base
                  "
                  @click="onView(data.item.forwardedmessageid)"
                >
                  View
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="forwardMessageDetail"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <div class="tw-p-4" v-if="forwardedmessagedetail">
        <div class="tw-flex tw-justify-between tw-mb-10">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>

          <div
            class="tw-cursor-pointer"
            @click="$bvModal.hide('forwardMessageDetail')"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L18 18" stroke="#0C3523" stroke-width="1.6" />
              <path d="M18 1L1 18" stroke="#0C3523" stroke-width="1.6" />
            </svg>
          </div>
        </div>
        <div class="tw-flex">
          <div class="tw-w-1/4">
            <h3 class="tw-text-xl tw-mb-6">Forwarded Request</h3>
          </div>
          <div class="tw-w-3/4">
            <div class="tw-grid tw-grid-cols-3">
              <div>
                <small class="tw-block tw-text-primary">Patient</small>
                <p>{{ forwardedmessagedetail.patientname }}</p>
              </div>
              <div>
                <small class="tw-block tw-text-primary">Date Forwarded</small>
                <p>
                  {{
                    parseDate(
                      forwardedmessagedetail.timestampcreated,
                      "MMMM d, yyyy"
                    )
                  }}
                </p>
              </div>
              <div>
                <small class="tw-block tw-text-primary">Date Resolved</small>
                <p>-</p>
              </div>
            </div>

            <div
              class="
                tw-flex
                tw-flex-grow
                tw-flex-col-reverse
                tw-bg-[#F9FBFB]
                tw-px-4
                tw-pt-4
                tw-overflow-y-scroll
                tw-max-h-[600px]
                tw-my-4
              "
            >
              <template v-for="(chat, i) in forwardedmessagedetail.messagelist">
                <div
                  :key="i"
                  class="bubble-chat tw-flex tw-items-end"
                  :class="[chat.userid != userid ? 'patient' : 'doctor']"
                >
                  <div
                    class="
                      tw-flex-shrink-0
                      img-holder
                      tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden
                    "
                    style="font-size: 0"
                  >
                    <img
                      class="tw-object-cover"
                      :src="getMemberProfileUrl(chat.userid)"
                      width="30"
                      height="30"
                      alt="name of the patient or doctor"
                    />
                  </div>

                  <div class="chat-container tw-flex-0">
                    <template
                      v-if="chat.attachment && chat.attachment.length > 0"
                    >
                      <template v-for="(chatAttachment, i) in chat.attachment">
                        <div
                          :key="i"
                          class="shared-image tw-flex tw-flex-wrap"
                          :class="chat.message ? 'tw-mb-3' : ''"
                          v-if="chatAttachment.type != 'application/pdf'"
                        >
                          <a
                            :href="chatAttachment.url"
                            variant="link"
                            download
                            target="_blank"
                          >
                            <img
                              class="tw-object-cover tw-w-full tw-h-full"
                              :src="chatAttachment.url"
                              width="250"
                              height="250"
                              :alt="chatAttachment.filename"
                            />
                          </a>
                        </div>
                        <template v-else>
                          <div :key="i" class="messages p-3">
                            <a
                              :href="chatAttachment.url"
                              variant="link"
                              download
                              ><b-icon
                                icon="arrow-down-circle-fill"
                                variant="primary"
                              ></b-icon>
                              {{ chatAttachment.filename }}</a
                            >
                          </div>
                        </template>
                      </template>
                    </template>
                    <span class="messages p-3" v-if="chat.message">
                      {{ chat.message }}
                    </span>
                  </div>
                  <div class="timestamp tw-flex-shrink-0 tw-items-end">
                    <small class="tw-text-[10px] tw-text-muted">
                      {{ moment.unix(chat.sentat).format("MMM DD, YYYY ") }}
                      {{ moment.unix(chat.sentat).format("hh:mm A") }}
                    </small>
                  </div>
                </div>
              </template>
            </div>

            <div v-if="forwardedmessagedetail.doctornotes">
              <small class="tw-block tw-text-primary">Doctor Notes</small>
              <p>{{ forwardedmessagedetail.doctornotes }}</p>
            </div>
            <div v-if="forwardedmessagedetail.adminnotes">
              <small class="tw-block tw-text-primary">Admin Notes</small>
              <p>{{ forwardedmessagedetail.adminnotes }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
export default {
  props: {
    conversationid: {
      type: String,
      default: "",
    },
    conversationmemberlist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: "dateforwarded",
          label: "Date Forwarded",
        },
        {
          key: "doctornotes",
          label: "Doctor's Notes",
        },
        {
          key: "adminnotes",
          label: "Admin Notes",
        },
        {
          key: "messagestatus",
          label: "Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
      forwardedmessagedetail: null,
      isModalVisible: false,
    };
  },
  computed: {
    patientName() {
      if (this.conversationmemberlist.length > 0) {
        return this.conversationmemberlist.find(
          (member) => member.type === "PATIENT"
        ).name;
      } else {
        return "";
      }
    },
    items() {
      return this.$store.state.message.conversationForwardRequests.map(
        (forwardRequest) => ({
          doctornotes: forwardRequest.doctornotes,
          adminnotes: forwardRequest.adminnotes,
          messagestatus: forwardRequest.messagestatus,
          dateforwarded: format(
            fromUnixTime(forwardRequest.timestampcreated),
            "MM/dd/yyyy"
          ),
          forwardedmessageid: forwardRequest.id,
        })
      );
    },
  },
  watch: {
    conversationid(val) {
      if (this.isModalVisible) {
        this.getConversationRequests(val);
      }
    },
  },
  mounted() {
    this.userid = localStorage.getItem("userid");
  },
  methods: {
    onView(forwardedmessageid, conversationid) {
      this.$store
        .dispatch("message/getSingleForwardRequest", {
          conversationid: conversationid ? conversationid : this.conversationid,
          forwardedmessageid,
        })
        .then((data) => {
          this.forwardedmessagedetail = data;
          this.$bvModal.show("forwardMessageDetail");
        });
    },
    getMemberProfileUrl(userid) {
      let member = this.conversationmemberlist.find((x) => x.userId === userid);
      if (member) {
        return member.profileUrl || require("@/assets/images/anon.jpeg");
      } else {
        return require("@/assets/images/anon.jpeg");
      }
    },
    getConversationRequests(conversationid) {
      this.$store.dispatch("message/getConversationForwardRequests", {
        conversationid,
      });
    },
    parseDate(date, dateFormat) {
      return format(fromUnixTime(date), dateFormat);
    },
    onShow() {
      this.getConversationRequests(this.conversationid);
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble-chat {
  width: 100%;
  margin-bottom: 1rem;

  .messages {
    display: inline-block;
    word-break: break-word;
  }

  &.patient {
    .img-holder {
      margin-right: 8px;
    }

    .timestamp {
      margin-left: 8px;
    }

    .shared-image {
      justify-content: start;
    }
  }

  &.doctor {
    flex-direction: row-reverse;
    justify-content: end;

    .img-holder {
      margin-left: 8px;
    }

    .timestamp {
      margin-right: 8px;
      text-align: right;
    }

    .shared-image {
      justify-content: end;
    }
  }
}

.bubble-chat.patient .messages {
  background-color: #ffecca;
}

.darkmode .bubble-chat.patient .messages {
  background-color: rgba(255, 236, 202, 0.1);
}

.bubble-chat.doctor .messages {
  background-color: #ffffff;
}

.chat-container {
  max-width: 70%;
  overflow: hidden;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    max-width: 50%;
  }
}
</style>
