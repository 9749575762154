var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "viewForwardMessage",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            size: "lg",
          },
          on: { show: _vm.onShow },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c("div", { staticClass: "tw-p-4" }, [
            _c("div", { staticClass: "tw-flex tw-justify-between tw-mb-10" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "27",
                    viewBox: "0 0 30 27",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                      fill: "#1A6A72",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "tw-cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("viewForwardMessage")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "19",
                        height: "19",
                        viewBox: "0 0 19 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 1L18 18",
                          stroke: "#0C3523",
                          "stroke-width": "1.6",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M18 1L1 18",
                          stroke: "#0C3523",
                          "stroke-width": "1.6",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              [
                _c("h3", { staticClass: "tw-text-xl tw-mb-6" }, [
                  _vm._v("Forward Requests"),
                ]),
                _c("b-table", {
                  attrs: {
                    responsive: "",
                    striped: "",
                    items: _vm.items,
                    fields: _vm.fields,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(action)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "text-secondary tw-capitalize tw-tracking-normal tw-text-base",
                                  attrs: {
                                    pill: "",
                                    variant: "link",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onView(
                                        data.item.forwardedmessageid
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" View ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "forwardMessageDetail",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _vm.forwardedmessagedetail
            ? _c("div", { staticClass: "tw-p-4" }, [
                _c(
                  "div",
                  { staticClass: "tw-flex tw-justify-between tw-mb-10" },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "30",
                          height: "27",
                          viewBox: "0 0 30 27",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                            fill: "#1A6A72",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tw-cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("forwardMessageDetail")
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "19",
                              height: "19",
                              viewBox: "0 0 19 19",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1 1L18 18",
                                stroke: "#0C3523",
                                "stroke-width": "1.6",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M18 1L1 18",
                                stroke: "#0C3523",
                                "stroke-width": "1.6",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "tw-flex" }, [
                  _c("div", { staticClass: "tw-w-1/4" }, [
                    _c("h3", { staticClass: "tw-text-xl tw-mb-6" }, [
                      _vm._v("Forwarded Request"),
                    ]),
                  ]),
                  _c("div", { staticClass: "tw-w-3/4" }, [
                    _c("div", { staticClass: "tw-grid tw-grid-cols-3" }, [
                      _c("div", [
                        _c(
                          "small",
                          { staticClass: "tw-block tw-text-primary" },
                          [_vm._v("Patient")]
                        ),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.forwardedmessagedetail.patientname)
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "small",
                          { staticClass: "tw-block tw-text-primary" },
                          [_vm._v("Date Forwarded")]
                        ),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.parseDate(
                                  _vm.forwardedmessagedetail.timestampcreated,
                                  "MMMM d, yyyy"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "small",
                          { staticClass: "tw-block tw-text-primary" },
                          [_vm._v("Date Resolved")]
                        ),
                        _c("p", [_vm._v("-")]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-flex-grow tw-flex-col-reverse tw-bg-[#F9FBFB] tw-px-4 tw-pt-4 tw-overflow-y-scroll tw-max-h-[600px] tw-my-4",
                      },
                      [
                        _vm._l(
                          _vm.forwardedmessagedetail.messagelist,
                          function (chat, i) {
                            return [
                              _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "bubble-chat tw-flex tw-items-end",
                                  class: [
                                    chat.userid != _vm.userid
                                      ? "patient"
                                      : "doctor",
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex-shrink-0 img-holder tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden",
                                      staticStyle: { "font-size": "0" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "tw-object-cover",
                                        attrs: {
                                          src: _vm.getMemberProfileUrl(
                                            chat.userid
                                          ),
                                          width: "30",
                                          height: "30",
                                          alt: "name of the patient or doctor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "chat-container tw-flex-0" },
                                    [
                                      chat.attachment &&
                                      chat.attachment.length > 0
                                        ? [
                                            _vm._l(
                                              chat.attachment,
                                              function (chatAttachment, i) {
                                                return [
                                                  chatAttachment.type !=
                                                  "application/pdf"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key: i,
                                                          staticClass:
                                                            "shared-image tw-flex tw-flex-wrap",
                                                          class: chat.message
                                                            ? "tw-mb-3"
                                                            : "",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: chatAttachment.url,
                                                                variant: "link",
                                                                download: "",
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "tw-object-cover tw-w-full tw-h-full",
                                                                attrs: {
                                                                  src: chatAttachment.url,
                                                                  width: "250",
                                                                  height: "250",
                                                                  alt: chatAttachment.filename,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "messages p-3",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: chatAttachment.url,
                                                                  variant:
                                                                    "link",
                                                                  download: "",
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "arrow-down-circle-fill",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      chatAttachment.filename
                                                                    )
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                      chat.message
                                        ? _c(
                                            "span",
                                            { staticClass: "messages p-3" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(chat.message) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "timestamp tw-flex-shrink-0 tw-items-end",
                                    },
                                    [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-text-[10px] tw-text-muted",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.moment
                                                  .unix(chat.sentat)
                                                  .format("MMM DD, YYYY ")
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.moment
                                                  .unix(chat.sentat)
                                                  .format("hh:mm A")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                    _vm.forwardedmessagedetail.doctornotes
                      ? _c("div", [
                          _c(
                            "small",
                            { staticClass: "tw-block tw-text-primary" },
                            [_vm._v("Doctor Notes")]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.forwardedmessagedetail.doctornotes)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.forwardedmessagedetail.adminnotes
                      ? _c("div", [
                          _c(
                            "small",
                            { staticClass: "tw-block tw-text-primary" },
                            [_vm._v("Admin Notes")]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.forwardedmessagedetail.adminnotes)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }