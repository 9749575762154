<template>
  <span :title="title" v-if="title">
    <IconRouted />
  </span>
</template>

<script>
import IconRouted from "@/components/icon/IconRouted.vue";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconRouted,
  },
  computed: {
    title() {
      switch (this.message.messageroutedto) {
        case "Both":
          return `Forwarded to Doctor and Admin`;
        case "Doctor no action":
          return `Forwarded to Doctor (No Action)`;
        case "Admin":
          return `Forwarded to Admin`;

        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
