var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canned-reply-modal pt-2" },
    [
      _c("p", { staticClass: "text-muted" }, [_vm._v("Reply")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-input-group",
                [
                  _c("b-input-group-append", [
                    _c("span", { staticClass: "input-group-text" }, [
                      _c("i", { staticClass: "fe fe-search" }),
                    ]),
                  ]),
                  _c("b-form-input", {
                    staticClass: "form-control list-search",
                    attrs: { placeholder: "Search" },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchReplies.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-select", {
                attrs: { options: _vm.categoryOptions },
                on: { input: _vm.getCategory },
                scopedSlots: _vm._u([
                  {
                    key: "first",
                    fn: function () {
                      return [
                        _c("b-form-select-option", { attrs: { value: "" } }, [
                          _vm._v(" All Replies "),
                        ]),
                        _c(
                          "b-form-select-option",
                          { attrs: { value: "default" } },
                          [_vm._v(" Defaults ")]
                        ),
                        _c(
                          "b-form-select-option",
                          { attrs: { value: "", disabled: "" } },
                          [_vm._v("----")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.selectedCategory,
                  callback: function ($$v) {
                    _vm.selectedCategory = $$v
                  },
                  expression: "selectedCategory",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-list-group",
                {
                  ref: "canned-reply-modal-list",
                  staticClass: "canned-reply-modal-list",
                },
                [
                  _vm.selectedCategory == "" ||
                  _vm.selectedCategory == "default"
                    ? _c(
                        "div",
                        _vm._l(_vm.defaultReplies, function (item) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: item.id,
                              attrs: { active: item.id == _vm.activeItemID },
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveItem(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-list-group-item",
                    { attrs: { value: "", disabled: "" } },
                    [_vm._v("----")]
                  ),
                  _vm._l(_vm.savedReplies, function (item) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: item.id,
                        attrs: { active: item.id == _vm.activeItemID },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveItem(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c("p", { staticClass: "text-muted" }, [_vm._v("Content")]),
              _c("b-form-textarea", {
                attrs: {
                  placeholder: "Message content here...",
                  rows: "6",
                  "no-resize": "",
                },
                model: {
                  value: _vm.activeItemContent,
                  callback: function ($$v) {
                    _vm.activeItemContent = $$v
                  },
                  expression: "activeItemContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.attachments && _vm.attachments.length > 0
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "ul",
                  { staticClass: "attachments-list" },
                  _vm._l(_vm.attachments, function (file, index) {
                    return _c("li", { key: `${index} - ${file.name}` }, [
                      _c(
                        "a",
                        { attrs: { href: file.previewURL, target: "_blank" } },
                        [_vm._v(_vm._s(file.filename))]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        {
          staticClass: "pt-4 pb-4 canned-footer",
          staticStyle: {
            position: "sticky",
            left: "0",
            right: "0",
            bottom: "0",
          },
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "6", sm: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "outline-primary",
                    size: "lg",
                    pill: "",
                    block: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-canned")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6", sm: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    size: "lg",
                    pill: "",
                    block: "",
                    disabled: !_vm.patientName,
                  },
                  on: { click: _vm.sendReply },
                },
                [_vm._v(" Select ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }